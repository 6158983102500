import React from "react";
import inc1 from "../assets/svgs/inc (1).svg";
import inc2 from "../assets/svgs/inc (2).svg";
import inc3 from "../assets/svgs/inc (3).svg";
import inc4 from "../assets/svgs/inc (4).svg";
import inc5 from "../assets/svgs/inc (5).svg";
import inc6 from "../assets/svgs/inc (6).svg";
import incimg from "../assets/images/low-size/inc (1).png";
import iconsb1 from "../assets/svgs/deticons (1).svg";
import iconsb2 from "../assets/svgs/deticons (2).svg";
import iconsb3 from "../assets/svgs/deticons (3).svg";
import detimg from "../assets/images/low-size/detimg.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from "swiper/modules";
import "swiper/css/navigation";

function DetailsSec() {
  return (
    <div className="max-w-[1200px] 2xl:w-[90%] xl:w-[90%] lg:w-[90%] md:w-[90%] sm:w-[90%] w-[100%] m-auto pb-[4rem] ">
      <div className="2xl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[100%] w-[90%] m-auto  bg-[#0F6483] 2xl:rounded-[10px] xl:rounded-[10px] lg:rounded-[10px] md:rounded-[10px] sm:rounded-[10px] rounded-[10px] space-y-[3rem] relative">
        <div className="2xl:flex xl:flex lg:flex md:flex sm:block block items-center justify-between  2xl:px-[6rem] xl:px-[6rem] lg:px-[4rem] md:px-[4rem] sm:px-[0.8rem] px-[0.8rem] 2xl:pt-[3rem] xl:pt-[3rem] lg:pt-[3rem] md:pt-[3rem] sm:pt-[1rem] pt-[1rem] 2xl:pb-[2rem] xl:pb-[2rem] lg:pb-[2rem] md:pb-[2rem] sm:pb-[1rem] pb-[1rem] 2xl:space-x-[3rem] xl:space-x-[3rem] lg:space-x-[3rem] md:space-x-[3rem] sm:space-x-[0rem] space-x-[0rem] 2xl:space-y-[0rem] xl:space-y-[0rem] lg:space-y-[0rem] md:space-y-[0rem] sm:space-y-[1rem] space-y-[1rem] ">
          <div className="w-[100%] max-w-[450px]">
            <img src={detimg} className="w-[100%]" alt="" />
          </div>
          <div className="2xl:w-[50%] xl:w-[50%] lg:w-[50%] md:w-[50%] sm:w-[100%] w-[100%] 2xl:pl-0 xl:pl-0 lg:pl-0 md:pl-0 sm:pl-1 pl-1">
            <div className="max-w-[495px] space-y-[15px]">
              <h3 className="2xl:text-[44px] xl:text-[44px] lg:text-[44px] md:text-[44px] sm:text-[22px] text-[22px]  text-[#fff] font-[700] leading-[100%] w-max mx-auto md:ml-0 md:mr-0">
                Love is in the details.
              </h3>
              <p className="text-[16px] font-[400] text-[#fff] text-center md:text-left">
                We handle moves of all distances, local to global! Our experts
                ensure a smooth transition, from packing & secure transport to
                furniture assembly & storage solutions.{" "}
              </p>
              <div className="space-y-[12px]">
                <div className="flex space-x-3">
                  <img src={iconsb1} alt="" />
                  <div>
                    <h4 className="2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[14px] text-[14px] font-[700] text-[#fff]">50</h4>
                    <p className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[12px] text-[12px] font-[400] text-[#fff]">
                      Bright new and clean Trucks in our fleet (and Growing)
                    </p>
                  </div>
                </div>
                <div className="flex space-x-3">
                  <img src={iconsb2} alt="" />
                  <div>
                    <h4 className="2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[14px] text-[14px] font-[700] text-[#fff]">99%</h4>
                    <p className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[12px] text-[12px] font-[400] text-[#fff]">
                      On time and best delivery in industry.
                    </p>
                  </div>
                </div>
                <div className="flex space-x-3">
                  <img src={iconsb3} alt="" />
                  <div>
                    <h4 className="2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[14px] text-[14px] font-[700] text-[#fff]">99%</h4>
                    <p className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[12px] text-[12px] font-[400] text-[#fff]">
                      NPS score across all the locations we have served
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex 2xl:bottom-[-10%]  xl:bottom-[-10%]  lg:bottom-[-10%]  mdbottom-[-10%] sm:bottom-[-5%] bottom-[-5%] w-[100%]  absolute justify-center z-[1]">
          <div className="h-[50px]  w-[80%] rounded-[6px] bg-[#0F6483]"></div>
        </div>
      </div>
      <div className="2xl:flex xl:flex lg:flex md:flex sm:block block justify-between pt-[6rem] 2xl:space-x-[65px] xl:space-x-[65px] lg:space-x-[65px] md:space-x-[65px] sm:space-x-[0px] space-x-[0px] 2xl:space-y-[0px] xl:space-y-[0px] lg:space-y-[0px] md:space-y-[0px] sm:space-y-[30px] space-y-[30px]">
        <div className="2xl:w-[48%] xl:w-[48%] lg:w-[48%] md:w-[48%] sm:w-[100%] w-[100%] space-y-[20px]">
          <h2 className="2xl:text-[32px] xl:text-[32px] lg:text-[32px] md:text-[32px] sm:text-[22px] text-[22px] font-[700] leading-[120%] 2xl:text-start xl:text-start lg:text-start md:text-start sm:text-center text-center">
            What’s included in <span className="textgr">every <br className="2xl:block xl:block lg:block md:block sm:hidden hidden" /> move</span>.
          </h2>
          <div className="2xl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[100%] w-[90%] m-auto">
            <img className="w-[100%]" src={incimg} alt="" />
          </div>
        </div>
        <div className="2xl:w-[45%] xl:w-[45%] lg:w-[45%] md:w-[45%] sm:w-[100%] w-[100%] 2xl:space-y-[32px] xl:space-y-[32px] lg:space-y-[32px] md:space-y-[32px] sm:space-y-[20px] space-y-[20px] 2xl:flex xl:flex lg:flex md:flex sm:hidden hidden 2xl:items-end  xl:items-end  lg:items-end  md:items-end  sm:items-start items-start 2xl:flex-col xl:flex-col lg:flex-col md:flex-col sm:flex-row flex-row overflow-hidden">
          <div className="flex space-x-[1rem]">
            <div className="rounded-[10px] bg-[#006083] w-[163px]  h-[158px] flex justify-between  items-center flex-col px-4 pt-8 pb-4">
              <img src={inc1} alt="" />
              <h4 className="text-center text-[14px] font-[700] text-[#fff]">
                Custom Wrapping and Packing
              </h4>
            </div>
            <div className="rounded-[10px] bg-[#006083] w-[163px]  h-[158px] flex justify-between  items-center flex-col px-4 pt-8 pb-4">
              <img src={inc2} alt="" />
              <h4 className="text-center text-[14px] font-[700] text-[#fff]">
                Assembly/ <br />Disassembly
              </h4>
            </div>
            <div className="rounded-[10px] bg-[#006083] w-[163px]  h-[158px] flex justify-between  items-center flex-col px-4 pt-7 pb-4">
              <img src={inc3} alt="" />
              <h4 className="text-center text-[14px] font-[700] text-[#fff]">
                Floors, corners and wall protection{" "}
              </h4>
            </div>
          </div>
          <div className="flex space-x-[1rem]">
            <div className="rounded-[10px] bg-[#006083] w-[163px]  h-[158px] flex justify-between  items-center flex-col px-2 pt-8 pb-4">
              <img src={inc4} alt="" />
              <h4 className="text-center text-[14px] font-[700] text-[#fff]">
                Labor, transportation, gas and tolls - all included
              </h4>
            </div>
            <div className="rounded-[10px] bg-[#006083] w-[163px]  h-[158px] flex justify-between  items-center flex-col px-4 pt-8 pb-4">
              <img src={inc5} alt="" />
              <h4 className="text-center text-[14px] font-[700] text-[#fff]">
                Safety measures we take
              </h4>
            </div>
            <div className="rounded-[10px] bg-[#006083] w-[163px]  h-[158px] flex justify-between  items-center flex-col px-4 pt-[4rem] pb-4">
              <img src={inc6} alt="" />
              <h4 className="text-center text-[14px] font-[700] text-[#fff]">
                See More{" "}
              </h4>
            </div>
          </div>
        </div>
        <div className="2xl:hidden xl:hidden pb-8 px-[2px] lg:hidden md:hidden sm:block block">
        <Swiper 
        spaceBetween={30}
        slidesPerView={2.3}
        pagination={{
          clickable: true,
        }}
        className="mySwiper !h-[200px]"
        modules={[Pagination]}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        >
            <SwiperSlide >
            <div className="rounded-[10px] bg-[#006083] w-[163px]  h-[158px] flex justify-between  items-center flex-col px-4 pt-8 pb-4">
              <img src={inc1} alt="" />
              <h4 className="text-center text-[14px] font-[700] text-[#fff]">
                Custom Wrapping and Packing
              </h4>
            </div>
            </SwiperSlide>
            <SwiperSlide >
            <div className="rounded-[10px] bg-[#006083] w-[163px]  h-[158px] flex justify-between  items-center flex-col px-4 pt-8 pb-4">
              <img src={inc2} alt="" />
              <h4 className="text-center text-[14px] font-[700] text-[#fff]">
                Assembly/Disassembly
              </h4>
            </div>
            </SwiperSlide>
            <SwiperSlide >
            <div className="rounded-[10px] bg-[#006083] w-[163px]  h-[158px] flex justify-between  items-center flex-col px-4 pt-7 pb-4">
              <img src={inc3} alt="" />
              <h4 className="text-center text-[14px] font-[700] text-[#fff]">
                Floors, corners and wall protection{" "}
              </h4>
            </div>
            </SwiperSlide>
            <SwiperSlide >
            <div className="rounded-[10px] bg-[#006083] w-[163px]  h-[158px] flex justify-between  items-center flex-col px-2 pt-8 pb-4">
              <img src={inc4} alt="" />
              <h4 className="text-center text-[14px] font-[700] text-[#fff]">
                Labor, transportation, gas and tolls - all included
              </h4>
            </div>
            </SwiperSlide>
            <SwiperSlide >
            <div className="rounded-[10px] bg-[#006083] w-[163px]  h-[158px] flex justify-between  items-center flex-col px-4 pt-8 pb-4">
              <img src={inc5} alt="" />
              <h4 className="text-center text-[14px] font-[700] text-[#fff]">
                Safety measures we take
              </h4>
            </div>
            </SwiperSlide>
            <SwiperSlide >
            <div className="rounded-[10px] bg-[#006083] w-[163px]  h-[158px] flex justify-between  items-center flex-col px-4 pt-[4rem] pb-4">
              <img src={inc6} alt="" />
              <h4 className="text-center text-[14px] font-[700] text-[#fff]">
                See More{" "}
              </h4>
            </div>
            </SwiperSlide>
        </Swiper>
      </div>
      </div>
        <div className="flex justify-center 2xl:pt-[60px] xl:pt-[60px] lg:pt-[60px] md:pt-[60px] sm:pt-[0px] pt-[0px]">
    
          <a href="#hero" className="flex justify-center w-[100%] max-w-[350px] m-auto">
          <button className="w-[100%]  2xl:max-w-[350px] xl:max-w-[350px] lg:max-w-[350px] md:max-w-[350px] sm:max-w-[250px] max-w-[250px] rounded-[5px] h-[48px] navbtn hover:!bg-transparent hover:border hover:border-[#006083] hover:text-[#000] text-[#fff] font-[700] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] md:text-[20px] sm:text-[18px] text-[18px]">
     GET 20% DISCOUNT NOW
          </button>
              </a>
     </div>
     <p id="reviews"></p>

    </div>
  );
}

export default DetailsSec;
