import React, {useEffect} from "react";
import HeroSection from "../HeroSection";
import Peoplesec from "../Peoplesec";
import AboutUsSec from "../AboutUsSec";
import TypesofServicesSec from "../TypesofServicesSec";
import DetailsSec from "../DetailsSec";
import OurreviewsSec from "../OurreviewsSec";
import ExploreSec from "../ExploreSec";
import PlanningSec from "../PlanningSec";
import FaqsSection from "../Faqs";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {};

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
   <div className="2xl:pt-[4rem] xl:pt-[4rem] lg:pt-[4rem] md:pt-[4rem] sm:pt-[4rem] pt-[4rem] " id="hero">
      <HeroSection />
      <Peoplesec />
      <AboutUsSec />
      <TypesofServicesSec />
      <DetailsSec />
      <OurreviewsSec />
      <ExploreSec />
      <PlanningSec />
      <FaqsSection />
   </div>
  );
}

export default Home;
