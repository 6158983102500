import React,{useEffect} from "react";
import {Routes, Route, Navigate } from "react-router-dom";
import {useLocation, useNavigate } from "react-router-dom";

import Home from "./Component/Pages/Home";
import TermsConditions from "./Component/Pages/Terms&Conditions";
import PrivacyPolicy from "./Component/Pages/PrivacyPolicy";

function App() {

  const { hash } = useLocation();

  const navigate = useNavigate();
  useEffect(() => {
    if (hash) {
      const target = document.querySelector(hash);
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
        // Remove the hash from the URL to avoid conflicts with React Router
        navigate({ hash: null });
      }
    }
  }, [hash, navigate]);
  return (
   <>
   <Routes>
    <Route path="/" element={<Home />} />
   <Route path="home" exact element={<Navigate to="/" />}  />
    <Route path="/terms-and-conditions" element={<TermsConditions />} />
    <Route path="privacy-policy" element={<PrivacyPolicy />} />
  </Routes>
   </>
  );
}

export default App;
