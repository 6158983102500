import React, { useState, useRef, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import "swiper/css/navigation";
import { useDebouncedCallback } from "use-debounce";
import Autocomplete from "react-google-autocomplete";
import Datepicker from "tailwind-datepicker-react";
import locicn from "../assets/svgs/locaicn.svg";
import stars from "../assets/svgs/stars.svg";
import dateicn from "../assets/svgs/deat.svg";
import iconsb1 from "../assets/svgs/heroicnb (1).svg";
import iconsb2 from "../assets/svgs/heroicnb (2).svg";
import iconsb3 from "../assets/svgs/heroicnb (3).svg";
import adds from "../assets/svgs/inpicn (1).svg";
import userna from "../assets/svgs/phones.svg";
import mob from "../assets/svgs/inpicn (2).svg";
import usericn from "../assets/images/uswer.png";
import heroimg from "../assets/images/low-size/heroimg.png";
import thkimg from "../assets/images/thkimg.png";
import { useSearchParams } from "react-router-dom";



function HeroSection() {

  const [searchParams, setSearchParams] = useSearchParams();

  const [activeDiv, setActiveDiv] = useState(0);
  const [location1, setLocation1] = useState(null);
  const [errorMessage1, setErrorMessage1] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessageMobile, setErrorMessageMobile] = useState("");
  const [errorMessageFullName, setErrorMessageFullName] = useState("");
  const [location2, setLocation2] = useState(null);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [mobileNumber, setMobileNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullNameValid, setfullNameValid] = useState(true);

  const [mobileNumberValid, setMobileNumberValid] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const touchStartX = useRef(0);
  const [animate, setAnimate] = useState(false);

  const apiKey = "AIzaSyDFw2pnyRvfZg64EgblvnRGakvc5fga9G4";

  const today = new Date();
  today.setHours(-1, 0, 0, 0);

  const options = {
    title: "",
    autoHide: true,
    todayBtn: false,
    clearBtn: true,
    clearBtnText: "Clear",
    theme: {
      background: "bg-white",
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "",
      disabledText: "",
      input: "",
      inputIcon: "",
      selected: "",
    },
    icons: {
      prev: () => <span>Previous</span>,
      next: () => <span>Next</span>,
    },
    datepickerClassNames: "top-12",
    language: "en",
    default: "Select Date",
    weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: "Select Date",
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
    minDate: today, 
  };


  const validateEmailWithDelay = async (email) => {
    await new Promise((resolve) => setTimeout(resolve, 2000));

    const validateEmail = {
      method: "GET",
      url: "https://quickemailverification.p.rapidapi.com/v1/verify",
      params: {
        email: email,
      },
      headers: {
        "x-rapidapi-key": "c7fd2f1ademsha59d7da8c1821d7p1b41aajsn197c7b66702b",
        "x-rapidapi-host": "quickemailverification.p.rapidapi.com",
        Authorization:
          "6e6684b0d88cc97b43af5b7d3781a2046db795c9acea5a15284a8a2d1254",
      },
    };

    try {
      const response = await axios.request(validateEmail);
      const { result } = response.data;

      if (result === "valid") {
        setEmailValid(true);
        setErrorMessageEmail("");
      } else {
        setEmailValid(false);
        setErrorMessageEmail("Email does not exist or is invalid");
      }
    } catch (error) {
      console.error("Error validating email:", error);
      setEmailValid(false);
      setErrorMessageEmail("Error validating email");
    }
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmail(email);
    validateEmail(email);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailValid(false);
      setErrorMessageEmail("Invalid email format");
      return;
    }

    debouncedValidateEmail(email);
  };

  const debouncedValidateEmail = useDebouncedCallback((email) => {
    validateEmailWithDelay(email);
  }, 500);

  const handleFullmailChange = (e) => {
    const name = e.target.value;
    setFullName(name);
    setfullNameValid(name);
  };
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailValid(emailPattern.test(email));
  };

  const formatPhoneNumber = (value) => {
    let cleaned = value.startsWith("+")
      ? "+" + value.slice(1).replace(/\D/g, "")
      : value.replace(/\D/g, "");

    if (!cleaned.startsWith("+1")) {
      cleaned = "+1" + cleaned.replace(/^\+/, "");
    }

    const match = cleaned.match(/^\+?(\d{1})(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      let formattedNumber = match[1] ? `+${match[1]}` : "";
      if (match[2]) formattedNumber += ` (${match[2]}`;
      if (match[3]) formattedNumber += `) ${match[3]}`;
      if (match[4]) formattedNumber += `-${match[4]}`;
      return formattedNumber;
    }

    return value;
  };

  const handleMobileNumberChange = (e) => {
    const input = e.target.value;
    const formattedNumber = formatPhoneNumber(input);

    const isValid = /^\+1 \(\d{3}\) \d{3}-\d{4}$/.test(formattedNumber);

    if (isValid || formattedNumber.length <= 17) {
      setMobileNumber(formattedNumber);
      setMobileNumberValid(isValid);
    }
  };

  const validateMobile = (mobile) => {
    if (mobile.length === 0 || mobile.length >= 10) {
      setMobileNumberValid(true);
    } else {
      setMobileNumberValid(false);
    }
  };

  const handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleChange = (date) => {
    console.log(date, "sdkdoaisa");
    setSelectedDate(date);
  };

  const handleClose = (state) => {
    setShow(state);
  };
  const handlePlaceSelected1 = (place) => {
    if (!place || !place.formatted_address) {
      setLocation1('');
      setErrorMessage1("Moving out location is required");
      return;
    }

    let address = place.formatted_address;
    let postalCode = "";

    place.address_components.forEach((component) => {
      if (component.types.includes("postal_code")) {
        postalCode = component.long_name;
      }
    });

    if (!address) {
      address = postalCode;
    }

    console.log(address);
    setLocation1(address);
    setErrorMessage1("");
  };

  const handlePlaceSelected2 = (place) => {
    if (!place || !place.formatted_address) {
      setLocation2('');
      setErrorMessage2("Moving in location is required");
      return;
    }

    let address = place.formatted_address;
    let postalCode = "";

    place.address_components.forEach((component) => {
      if (component.types.includes("postal_code")) {
        postalCode = component.long_name;
      }
    });

    if (!address) {
      address = postalCode;
    }

    console.log(address);
    setLocation2(address);
    setErrorMessage2("");
  };
  const handleInputChange1 = (e) => {
    if (!e.target.value) {
      setLocation1('');
      setErrorMessage1("Moving out location is required");
    }
  };

  const handleInputChange2 = (e) => {
    if (!e.target.value) {
      setLocation2('');
      setErrorMessage2("Moving in location is required");
    }
  };

  const handleDivClick = (index) => {
    if (index === 0) {
      setActiveDiv(0);
      setErrorMessage("");
      setAnimate(true);
      setTimeout(() => {
        setAnimate(false);
        setActiveDiv(0);
      }, 300);
    } else if (index === 1) {
      let isValid = true;

      if (!location1) {
        setErrorMessage1("Enter Moving out location");
        isValid = false;
      } else {
        setErrorMessage1("");
      }

      if (!location2) {
        setErrorMessage2("Enter Moving in location");
        isValid = false;
      } else {
        setErrorMessage2("");
      }

      if (isValid) {
        setAnimate(true);
        setTimeout(() => {
          setAnimate(false);
          setActiveDiv(1);
        }, 300);
        setErrorMessage("");
      }
    }
  };
  const sendDataToGoogleSheets = async () => {
    const apiUrl =
      "https://sheet.best/api/sheets/40427cf7-637e-4874-8ace-7e9797e4606b";

    const payload = {
      "Moving out location": location1,
      "Moving in location": location2,
      Date: selectedDate,
      Email: email,
      "Full Name": fullName,
      "Mobile Number": `\`${mobileNumber}\``,
    };

    try {
      const response = await axios.post(apiUrl, payload);
      if (response.status === 200) {
        setActiveDiv(2);
        searchParams.set('form_filled', 'true');
        setSearchParams(searchParams);
        setEmail(!email);
        setFullName("");
        setEmailValid(true);
        setMobileNumber("");
        setMobileNumberValid("");
        setErrorMessage("");
      } else {
        setErrorMessage("Failed to send data. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  const handleNextClickFirst = () => {
    let isValid = true;

    if (!location1) {
      setErrorMessage1("Enter Moving out location");
      isValid = false;
    } else {
      setErrorMessage1("");
    }

    if (!location2) {
      setErrorMessage2("Enter Moving in location");
      isValid = false;
    } else {
      setErrorMessage2("");
    }

    if (isValid) {
      setAnimate(true);
      setTimeout(() => {
        setAnimate(false);
        setActiveDiv(1);
      }, 300);
    }
  };
  const handleNextClickSec = async () => {
    let isValid = true;

    if (!email || !emailValid) {
      setErrorMessageEmail("Please enter a valid email");
      isValid = false;
    } else {
      setErrorMessageEmail("");
    }

    if (!mobileNumber || !mobileNumberValid) {
      setErrorMessageMobile("Please enter a valid mobile number");
      isValid = false;
    } else {
      setErrorMessageMobile("");
    }

    if (!fullName) {
      setErrorMessageFullName("Please enter your full name");
      isValid = false;
    } else {
      setErrorMessageFullName("");
    }

    if (isValid) {
      await sendDataToGoogleSheets();
    }
  };

  useEffect(() => {
    const formIsValid =
      location1 &&
      location2 &&
      selectedDate &&
      emailValid &&
      fullName &&
      mobileNumberValid;

    setIsFormValid(formIsValid);
  }, [
    location1,
    location2,
    selectedDate,
    emailValid,
    mobileNumberValid,
    fullName,
  ]);

  return (
    <div className="relative max-w-[1200px] w-[90%] m-auto 2xl:pt-[4rem] xl:pt-[3rem] lg:pt-[3rem] md:pt-[3rem] sm:pt-[2rem] pt-[2rem] pb-[2rem]">
      <div className="2xl:flex xl:flex lg:flex md:flex sm:block block justify-between space-y-[2rem] items-center">
        <div className="w-[100%] max-w-[567px] space-y-[1rem]">
          <div className="space-y-[2px]">
            <img className="w-[120px]" src={stars} alt="" />
            <div className="flex space-x-3 items-center">
              <p className="bgbrcolorgr m-0 leading-[12px] text-[12px] !rounded-[50px] text-[#fff] px-[26px] py-[8px]">
                96% Positive
              </p>
              <p className="text-[12px] !font-[600] m-0">4.8 (1100+ reviews)</p>
            </div>
          </div>
          <div className="">
            <h1 className="2xl:text-[32px] xl:text-[32px] lg:text-[32px] md:text-[32px] sm:text-[22px] text-[22px] font-[700] leading-[normal]">
              Unlock a seamless and stress-free relocation experience{" "}
              <span className="textgr">
                with Titan Movers USA, your ultimate moving
              </span>
            </h1>
          </div>
          <div className={`swiper-container   ${activeDiv === 0 ? "" : ""}`}>
            <div
              className={`shadow-2xl !w-[100%] bgcardshadow 2xl:px-[31px] xl:px-[31px] lg:px-[31px] md:px-[31px] sm:px-[15px] px-[15px] pt-[30px] pb-[8px] 2xl:space-y-[20px] xl:space-y-[20px] lg:space-y-[20px] md:space-y-[15px] sm:space-y-[15px] space-y-[15px] transition-transform transform ${
                animate ? "slide-out" : "slide-in"
              }`}
            >
              {activeDiv === 0 && (
                <>
                  <div className="space-y-[15px]">
                    <div className="flex space-x-4">
                      <div className="w-[50%] space-y-[8px]">
                        <label className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[14px] text-[14px] font-[400]">
                          Moving out location
                        </label>
                        <div>
                          <div className="flex h-[38px] locinput bg-[#F8F8F8] rounded-[3px] justify-between items-center px-2 w-[100%]">
                            <Autocomplete
                              placeholder="zip, city or country"
                              className="w-[100%] h-[100%] text-[14px] bg-[#F8F8F8] outline-none"
                              apiKey={apiKey}
                              onPlaceSelected={handlePlaceSelected1}
                              options={{
                                types: ["(regions)"],
                              }}
                              onChange={handleInputChange1}
                            />

                            <img
                              src={locicn}
                              className="h-[30px]"
                              alt="location icon"
                            />
                          </div>
                          <div className="bgborder"></div>
                          {errorMessage1 && (
                            <p className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[14px] text-[14px] text-[red]">
                              {errorMessage1}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="w-[50%] space-y-[8px]">
                        <label className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[14px] text-[14px] font-[400]">
                          Moving in location
                        </label>
                        <div>
                          <div className="flex h-[38px] bg-[#F8F8F8] rounded-[3px] justify-between items-center px-2 w-[100%] locinput">
                            <Autocomplete
                              placeholder="zip, city or country"
                              className="w-[100%] h-[100%] text-[14px] bg-[#F8F8F8] outline-none"
                              apiKey={apiKey}
                              onPlaceSelected={handlePlaceSelected2}
                              options={{
                                types: ["(regions)"],
                              }}
                              onChange={handleInputChange2}
                            />
                            <img
                              src={locicn}
                              className="h-[30px]"
                              alt="location icon"
                            />
                          </div>
                          <div className="bgborder"></div>
                          {errorMessage2 && (
                            <p className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[14px] text-[14px] text-[red]">
                              {errorMessage2}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="space-y-[8px]">
                      <label className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[14px] text-[14px] font-[400]">
                        Select a date
                      </label>
                      <div className="w-[100%]">
                        <div className="inputdate w-[100%]  bg-[#F8F8F8] rounded-[3px] px-2">
                          <div className="relative w-[100%] z-[1]">
                            <Datepicker
                              classNames="border-none outline-hidden"
                              options={options}
                              onChange={handleChange}
                              show={show}
                              setShow={handleClose}
                            />
                            <div className="absolute h-[30px] top-[12%] cursor-pointer  end-0 flex items-center ps-3 z-[-1]">
                              <img
                                src={dateicn}
                                className="h-[30px]"
                                alt="date icon"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="bgborder"></div>
                      </div>
                    </div>
                  </div>
                  {errorMessage && (
                    <div className="text-red-500 text-sm mt-2">
                      {errorMessage}
                    </div>
                  )}
                  <div className="!rounded-[5px] ">
                    <button
                      onClick={handleNextClickFirst}
                      className="w-[100%] hover:opacity-[0.9] !rounded-[5px] h-[48px] bgbrcolor text-[#fff] font-[700] 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[14px] sm:text-[14px] text-[14px]"
                    >
                      Get Your Quote
                    </button>
                  </div>
                </>
              )}
              {activeDiv === 1 && (
                <>
                  <div className="space-y-[10px]">
                    <p className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[14px] text-[14px] font-[700]">
                      Enter your details here to receive your quote!
                    </p>
                    <div className="space-y-[14px]">
                      <div>
                        <div className="flex h-[38px] bg-[#F8F8F8] rounded-[3px] justify-between items-center px-2 w-[100%]">
                          <input
                            type="text"
                            value={fullName}
                            onChange={handleFullmailChange}
                            className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[14px] text-[14px] h-[38px] w-[80%] bg-[#F8F8F8] outline-none"
                            placeholder="Full Name"
                          />
                          <img
                            src={userna}
                            className="h-[30px]"
                            alt="date icon"
                          />
                        </div>
                        <div className="bgborder"></div>
                        {errorMessageFullName && (
                          <p className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[14px] text-[14px] text-[red]">
                            {errorMessageFullName}
                          </p>
                        )}
                      </div>
                      <div>
                        <div className="flex h-[38px] bg-[#F8F8F8] rounded-[3px] justify-between items-center px-2 w-[100%]">
                          <input
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[14px] text-[14px] h-[38px] w-[80%] bg-[#F8F8F8] outline-none"
                            placeholder="Email address"
                          />
                          <a href={`mailto:${email}`} className="">
                            <img
                              src={adds}
                              className="h-[30px]"
                              alt="date icon"
                            />
                          </a>
                        </div>
                        <div className="bgborder"></div>
                        {errorMessageEmail && (
                          <p className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[14px] text-[14px] text-[red]">
                            {errorMessageEmail}
                          </p>
                        )}
                      </div>
                      <div>
                        <div className="flex h-[38px] bg-[#F8F8F8] rounded-[3px] justify-between items-center px-2 w-[100%]">
                          <input
                            type="text"
                            value={mobileNumber}
                            onChange={handleMobileNumberChange}
                            onKeyPress={handleKeyPress}
                            className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[14px] text-[14px] h-[38px] w-[80%] bg-[#F8F8F8] outline-none"
                            placeholder="Mobile number"
                          />
                          <a
                            href={`tel:${mobileNumber}`}
                            className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block"
                          >
                            <img
                              src={mob}
                              className="h-[30px]"
                              alt="date icon"
                            />
                          </a>
                          <img
                            src={mob}
                            className="h-[30px] 2xl:block xl:block lg:block md:block sm:hidden hidden"
                            alt="date icon"
                          />
                        </div>
                        <div className="bgborder"></div>
                        {errorMessageMobile && (
                          <p className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[14px] text-[14px] text-[red]">
                            {errorMessageMobile}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {errorMessage && (
                    <div className="text-red-500 text-sm mt-2">
                      {errorMessage}
                    </div>
                  )}
                  <div className="!rounded-[5px] pt-[0px]">
                    <button
                      onClick={handleNextClickSec}
                      className="w-[100%] hover:opacity-[0.9] !rounded-[5px] h-[48px] bgbrcolor text-[#fff] font-[700] 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[14px] sm:text-[14px] text-[14px]"
                    >
                      Get Your Quote
                    </button>
                  </div>
                </>
              )}
              {activeDiv === 2 && (
                <>
                  <div>
                    <div className=" flex flex-col justify-center items-center space-y-[20px]">
                      <img src={thkimg} alt="" />
                      <h2 className="2xl:text-[30px] leading-[120%] xl:text-[30px] lg:text-[30px] md:text-[30px] sm:text-[22px] text-[22px] font-[700] textgr text-center">
                        Thank You for taking an interest in Titan Movers USA!
                      </h2>
                      <p className="text-[14px] font-[400] text-center">
                        We've received your information and our expert team is
                        working hard to create your personalized moving quote.
                        Sit back and relax – you'll receive a detailed email
                        soon with an estimated timeframe, competitive pricing,
                        and all the details you need to plan your stress-free
                        move.
                      </p>
                    </div>
                  </div>
                </>
              )}

              {(activeDiv === 0 || activeDiv === 1) && (
                <div className="flex 2xl:space-x-4 xl:space-x-4 lg:space-x-4 md:space-x-4 sm:space-x-2 space-x-2 items-center">
                  <div>
                    <img
                      className="2xl:h-[30px] xl:h-[30px] lg:h-[30px] md:h-[30px] sm:h-[19px] h-[19px]"
                      src={usericn}
                      alt=""
                    />
                  </div>
                  <p className="text-[#000] font-[400] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[14px] text-[14px] leading-[100%]">
                  1,550 people shipped using movers last week
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-center space-x-2 items-center">
            <div
              className={`h-[6px] rounded-[20px] cursor-pointer ${
                activeDiv === 0
                  ? "bg-[#13627E] !w-[16px]"
                  : "bg-gray-400  w-[8px]"
              }`}
              onClick={() => handleDivClick(0)}
            ></div>
            <div
              className={`h-[6px] rounded-[20px] cursor-pointer ${
                activeDiv === 1
                  ? "bg-[#13627E] !w-[16px]"
                  : "bg-gray-400  w-[8px]"
              }`}
              onClick={() => handleDivClick(1)}
            ></div>
            <div
              className={`h-[6px] rounded-[20px] cursor-pointer ${
                activeDiv === 2
                  ? "bg-[#13627E] !w-[16px]"
                  : "bg-gray-400  w-[8px]"
              }`}
              onClick={() => handleDivClick(2)}
            ></div>
          </div>
        </div>
        <div className="2xl:hidden xl:hidden md:hidden sm:flex flex w-[100%] max-w-[540px] pt-[0rem] justify-between items-between">
          <div className="flex 2xl:space-x-4 xl:space-x-4 lg:space-x-4 md:space-x-4 sm:space-x-2 space-x-2 items-center">
            <img src={iconsb1} alt="" />
            <h4 className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[12px] text-[12px] font-[700] leading-[120%]">
              Scratch-Free <br />
              Guarantee
            </h4>
          </div>
          <div className="flex 2xl:space-x-4 xl:space-x-4 lg:space-x-4 md:space-x-4 sm:space-x-2 space-x-2 items-center">
            <img src={iconsb2} alt="" />
            <h4 className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[12px] text-[12px] font-[700] leading-[120%]">
              Vetted <br />
              Carriers
            </h4>
          </div>
          <div className="flex 2xl:space-x-4 xl:space-x-4 lg:space-x-4 md:space-x-4 sm:space-x-2 space-x-2 items-center">
            <img src={iconsb3} alt="" />
            <h4 className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[12px] text-[12px] font-[700] leading-[120%]">
              Transparent <br />
              Costs
            </h4>
          </div>
        </div>
        <div className="w-[100%] max-w-[500px] ">
          <img className="w-[100%] mt-[22px]" src={heroimg} alt="" />
        </div>
      </div>
      <div className="2xl:flex xl:flex md:flex sm:hidden hidden w-[100%] max-w-[540px] pt-[1rem] justify-between items-between">
        <div className="flex 2xl:space-x-4 xl:space-x-4 lg:space-x-4 md:space-x-4 sm:space-x-2 space-x-2 items-center">
          <img src={iconsb1} alt="" />
          <h4 className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[12px] text-[12px] font-[700] leading-[120%]">
            Scratch-Free <br />
            Guarantee
          </h4>
        </div>
        <div className="flex 2xl:space-x-4 xl:space-x-4 lg:space-x-4 md:space-x-4 sm:space-x-2 space-x-2 items-center">
          <img src={iconsb2} alt="" />
          <h4 className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[12px] text-[12px] font-[700] leading-[120%]">
            Vetted <br />
            Carriers
          </h4>
        </div>
        <div className="flex 2xl:space-x-4 xl:space-x-4 lg:space-x-4 md:space-x-4 sm:space-x-2 space-x-2 items-center">
          <img src={iconsb3} alt="" />
          <h4 className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[12px] text-[12px] font-[700] leading-[120%]">
            Transparent <br />
            Costs
          </h4>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
