import React from "react";
import map from "../assets/images/low-size/map.png";

function ExploreSec() {
  return (
    <div className="max-w-[1200px] w-[90%] m-auto py-[5.5rem] ">
      <div className="">
        <div className="2xl:flex xl:flex lg:flex md:flex sm:block block justify-between 2xl:space-x-[2rem] xl:space-x-[2rem] lg:space-x-[2rem] md:space-x-[2rem] sm:space-x-[0rem] space-x-[0rem] 2xl:space-y-[0rem] xl:space-y-[0rem] lg:space-y-[0rem]  md:space-y-[0rem] sm:space-y-[2rem] space-y-[2rem]">
          <div className="2xl:w-[50%] xl:w-[50%] lg:w-[50%] md:w-[50%] sm:w-[100%] w-[100%]">
            <img src={map} alt="" />
          </div>
          <div className="space-y-[25px] pt-[12px] 2xl:w-[50%] xl:w-[50%] lg:w-[50%] md:w-[50%] sm:w-[100%] w-[100%]">
            <h2 className="2xl:text-[32px] xl:text-[32px] lg:text-[32px] md:text-[32px] sm:text-[24px] text-[24px]  font-[700] 2xl:text-start xl:text-start  lg:text-start  md:text-start  sm:text-center text-center  ">
              Explore our Multiple <span className="textgr">Locations</span>.
            </h2>
            <p className="text-[16px] font-[400] 2xl:pb-[2.3rem] xl:pb-[2.3rem] lg:pb-[2.3rem] md:pb-[2.3rem] sm:pb-[0rem] pb-[0rem] 2xl:text-start xl:text-start  lg:text-start  md:text-start  sm:text-start text-start">
            Whether you're relocating across town or across the nation, our trusted crews provide the same dedication and expertise you expect. Explore our local movers today and discover a stress-free moving experience, no matter your destination. Get a free quote and let us handle the logistics, so you can focus on the excitement of your new chapter!
            </p>

            <div className="flex justify-center">
              <a href="#hero" className="w-[100%] flex justify-center">
                <button className="2xl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[60%] w-[60%] bgbrcolor rounded-[5px] h-[48px] text-[#fff] font-[700] 2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[14px] sm:text-[18px] text-[18px]">
                Get Your Quote
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExploreSec;
