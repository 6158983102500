import React, {useEffect} from "react";

function TermsConditions() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const handleScroll = () => {};
    
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);
  return (
    <>
      <div className="max-w-[1200px] pb-[4rem] pt-[8rem] m-auto 2xl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[90%] w-[90%] space-y-[20px]">
        <div>
          <h4 className="text-[32px] font-[500]">Terms and Conditions</h4>
          <p className="text-[14px] font-[400]">
            These terms and conditions outline the rules and regulations for the
            use of Titan Movers USA's Website. By accessing this website we
            assume you accept these terms and conditions in full. Do not
            continue to use Titan Movers USA's website if you do not accept all
            of the terms and conditions stated on this page.
          </p>{" "}
          <br />
          <p className="text-[14px] font-[400]">
            These terms and conditions outline the rules and The following
            terminology applies to these Terms and Conditions, Privacy Statement
            and Disclaimer Notice, and any or all Agreements: "Client", “You”
            and “Your” refers to you, the person accessing this website and
            accepting the Company’s terms and conditions. "The Company",
            “Ourselves”, “We”, “Our” and "Us", refers to our Company. “Party”,
            “Parties”, or “Us”, refers to both the Client and ourselves, or
            either the Client or ourselves. All terms refer to the offer,
            acceptance, and consideration of payment necessary to undertake the
            process of our assistance to the Client in the most appropriate
            manner, whether by formal meetings of a fixed duration or any other
            means, for the express purpose of meeting the Client’s needs in
            respect of provision of the Company’s stated services/products, in
            accordance with and subject to, prevailing law of United States. Any
            use of the above terminology or other words in the singular, plural,
            capitalization, and/or he/she or they, are taken as interchangeable
            and therefore as referring to the same.
          </p>
        </div>
        <div>
          <h4 className="text-[16px] font-[500]">Cookies</h4>
          <p className="text-[14px] font-[400]">
            We employ the use of cookies. By using Titan Movers USA's website
            you consent to the use of cookies in accordance with Titan Movers
            USA’s privacy policy. Most of the modern day interactive websites
            use cookies to enable us to retrieve user details for each visit.
            Cookies are used in some areas of our site to enable the
            functionality of this area and ease of use for those people
            visiting. Some of our affiliate/advertising partners may also use
            cookies.
          </p>{" "}
        </div>
        <div>
          <h4 className="text-[16px] font-[500]">License</h4>
          <p className="text-[14px] font-[400]">
            Unless otherwise stated, Titan Movers USA and/or its licensors own
            the intellectual property rights for all material on Titan Movers
            USA All intellectual property rights are reserved. You may view
            and/or print pages from/for your own personal use subject to
            restrictions set in these terms and conditions.
          </p>{" "}
         <div className="space-y-[15px] ">
            <p className="text-[14px] font-[500] italic">You must not:</p>
         <ul className="pl-5 space-y-[4px]">
            <li className="text-[14px] font-[400] list-decimal">
              Republish material from /
            </li>
            <li className="text-[14px] font-[400] list-decimal">
              Sell, rent, or sub-license material from /
            </li>
            <li className="text-[14px] font-[400] list-decimal">
              Reproduce, duplicate, or copy material from /
            </li>
            <li className="text-[14px] font-[400] list-decimal">
              Redistribute content from Titan Movers USA (unless content is
              specifically made for redistribution).
            </li>
          </ul>
         </div>
        </div>
        <div>
          <h4 className="text-[16px] font-[500]">User Comments</h4>
          <p className="text-[14px] font-[400]">
          This Agreement shall begin on the date hereof.
          </p>{" "}
        </div>
        <div>
          <h4 className="text-[16px] font-[500]">User comments feedback</h4>
          <p className="text-[14px] font-[400]">
          Certain parts of this website offer the opportunity for users to post and exchange opinions, information, material, and data ('Comments') in areas of the website. Titan Movers USA does not screen, edit, publish, or review Comments prior to their appearance on the website and Comments do not reflect the views or opinions of Titan Movers USA, its agents, or affiliates. Comments reflect the view and opinion of the person who posts such a view or opinion. To the extent permitted by applicable laws Titan Movers USA shall not be responsible or liable for the Comments or for any loss cost, liability, damages, or expenses caused and or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website. <br />
          Titan Movers USA reserves the right to monitor all Comments and to remove any Comments which it considers in its absolute discretion to be inappropriate, offensive, or otherwise in breach of these Terms and Conditions.
          </p>{" "}
          <div className="space-y-[15px]">
            <p className="text-[14px] font-[500] italic">
            You warrant and represent that:</p>
         <ul className="pl-5 space-y-[4px]">
            <li className="text-[14px] font-[400] list-decimal">
            You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;
            </li>
            <li className="text-[14px] font-[400] list-decimal">
            The Comments do not infringe any intellectual property right, including without limitation copyright, patent or trademark, or other proprietary right of any third party;
            </li>
            <li className="text-[14px] font-[400] list-decimal">
            The Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material or material which is an invasion of privacy
            </li>
            <li className="text-[14px] font-[400] list-decimal">
            The Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material or material which is an invasion of privacy
            </li>
            <li className="text-[14px] font-[400] list-decimal">
            You hereby grant to Titan Movers USA a non-exclusive royalty-free license to use, reproduce, edit, and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats, or media.
            </li>
          </ul>
         </div>
        </div>
        <div>
          <h4 className="text-[16px] font-[700]">Hyperlinking to our Content</h4>
          <p className="text-[14px] font-[600] italic">The following organizations may link to our Web site without prior written approval:
          </p>{" "}
          <div className="space-y-[15px]">
         <ul className="pl-5 space-y-[4px]">
            <li className="text-[14px] font-[400] list-disc">Government agencies; Search engines;
            </li>
            <li className="text-[14px] font-[400] list-disc">News organizations;
            </li>
            <li className="text-[14px] font-[400] list-disc">Online directory distributors when they list us in the directory may link to our Web site in the same manner as they hyperlink to the Web sites of other listed businesses; and
            </li>
            <li className="text-[14px] font-[400] list-disc">Systemwide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Website.
            </li>
          </ul> <br />
          <p className="text-[14px] font-[400]">These organizations may link to our home page, to publications, or to other Web site information so long as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.</p>

         </div>
        </div>
        <div>
          <h4 className="text-[16px] font-[700]">We may consider and approve in our sole discretion other link requests from the following types of organizations:</h4>
       
          <div className="space-y-[15px]">
         <ul className="pl-5 space-y-[4px]">
            <li className="text-[14px] font-[400] list-disc">commonly-known consumer and/or business information sources such as Chambers of Commerce, American Automobile Association, AARP, and Consumers Union;
            </li>
            <li className="text-[14px] font-[400] list-disc">dot.com community sites;
            </li>
            <li className="text-[14px] font-[400] list-disc">associations or other groups representing charities, including charity giving sites,
            </li>
            <li className="text-[14px] font-[400] list-disc">online directory distributors;
            </li>
            <li className="text-[14px] font-[400] list-disc">internet portals
            </li>
            <li className="text-[14px] font-[400] list-disc">accounting, law, and consulting firms whose primary clients are businesses; and
            </li>
            <li className="text-[14px] font-[400] list-disc">educational institutions and trade associations.
            </li>
          </ul> <br />
          <p className="text-[14px] font-[400]">We will approve link requests from these organizations if we determine that: (a) the link would not reflect unfavorably on us or our accredited businesses (for example, trade associations or other organizations representing inherently suspect types of business, such as work-at-home opportunities, shall not be allowed to link); (b)the organization does not have an unsatisfactory record with us; (c) the benefit to us from the visibility associated with the hyperlink outweighs the absence of Titan Movers USA; and (d) where the link is in the context of general resource information or is otherwise consistent with editorial content in a newsletter or similar product furthering the mission of the organization.</p> <br />
          <p className="text-[14px] font-[400]">These organizations may link to our home page, to publications, or to other Web site information so long as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and it products or services; and (c) fits within the context of the linking party's site.</p> <br />
          <p className="text-[14px] font-[400]">If you are among the organizations listed in paragraph 2 above and are interested in linking to our website, you must notify us by sending an e-mail to <span className="font-[600]">titanmoversusa@hotmail.com</span>. Please include your name, your organization name, contact information (such as a phone number and/or e-mail address) as well as the URL of your site, a list of any URLs from which you intend to link to our Web site, and a list of the URL(s) on our site to which you would like to link. Allow 2-3 weeks for a response.</p>

         </div>
        </div>
        <div>
          <h4 className="text-[16px] font-[700]">Approved organizations may hyperlink to our Web site as follows:</h4>
       
          <div className="space-y-[15px]">
         <ul className="pl-5 space-y-[4px]">
            <li className="text-[14px] font-[400] list-disc">By use of our corporate name; or
            </li>
            <li className="text-[14px] font-[400] list-disc">By use of the uniform resource locator (Web address) being linked to; or
            </li>
            <li className="text-[14px] font-[400] list-disc">By use of any other description of our Web site or material being linked to that makes sense within the context and format of content on the linking party's site.
            </li>
            <li className="text-[14px] font-[400] list-disc">No use of (name)’s logo or other artwork will be allowed for linking absent a trademark license agreement.
            </li>
          </ul> 
         </div>
        </div>
        <div>
          <h4 className="text-[16px] font-[500]">Iframes</h4>
          <p className="text-[14px] font-[400]">
          Without prior approval and express written permission, you may not create frames around our Web pages or use other techniques that alter in any way the visual presentation or appearance of our Web site.
          </p>{" "}
        </div>
        <div className="space-y-[20px]">
          <h4 className="text-[16px] font-[500]">Reservation of rights</h4>
         <div >
            <h4 className="text-[16px] font-[00] pl-2">1. Content Liability</h4>
            <p className="text-[14px] font-[400]">We shall have no responsibility or liability for any content appearing on your Web site. You agree to indemnify and defend us against all claims arising out of or based upon your Website. No link(s) may appear on any page on your Web site or within any context containing content or materials that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise, violates, or advocates the infringement or other violation of, any third party rights.</p>
         </div>
         <div>
            <h4 className="text-[16px] font-[00] pl-2">2. Reservation of Rights</h4>
            <p className="text-[14px] font-[400]">We shall have no responsibility or liability for any content appearing on your Web site. You agree to indemnify and defend us against all claims arising out of or based upon your Website. No link(s) may appear on any page on your Web site or within any context containing content or materials that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise, violates, or advocates the infringement or other violation of, any third party rights.</p>
         </div>
         <div>
            <h4 className="text-[16px] font-[00] pl-2">3. Removal of Links from our Website</h4>
            <p className="text-[14px] font-[400]">If you find any link on our Web site or any linked website objectionable for any reason, you may contact us about this. We will consider requests to remove links but will have no obligation to do so or to respond directly to you.</p> <br />
            <p className="text-[14px] font-[400]">Whilst we endeavor to ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we commit to ensuring that the website remains available or that the material on the website is kept up to date.</p>
         </div>
         <div>
            <h4 className="text-[16px] font-[00] pl-2">3. Removal of Links from our Website</h4>
            <p className="text-[14px] font-[400]">If you find any link on our Web site or any linked website objectionable for any reason, you may contact us about this. We will consider requests to remove links but will have no obligation to do so or to respond directly to you.</p> <br />
            <p className="text-[14px] font-[400]">Whilst we endeavor to ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we commit to ensuring that the website remains available or that the material on the website is kept up to date.</p>
         </div>
        </div>
        <div>
        <h4 className="text-[16px] font-[00]">Disclaimer</h4>
            <p className="text-[14px] font-[400]">To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose, and/or the use of reasonable care and skill). Nothing in this disclaimer will:</p> <br />
            <ul className="pl-5 space-y-[4px]">
            <li className="text-[14px] font-[400] list-decimal">
            limit or exclude our or your liability for death or personal injury resulting from negligence;
            </li>
            <li className="text-[14px] font-[400] list-decimal">limit or exclude our or your liability for fraud or fraudulent misrepresentation;
            </li>
            <li className="text-[14px] font-[400] list-decimal">limit any of our or your liabilities in any way that is not permitted under applicable law; or
            </li>
            <li className="text-[14px] font-[400] list-decimal">exclude any of our or your liabilities that may not be excluded under applicable law.
            </li>
            <li className="text-[14px] font-[400] list-decimal">The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or in relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort (including negligence) and for breach of statutory duty.
            </li>
          </ul> <br />
            <p className="text-[14px] font-[400]">To the extent that the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
        </div>
      </div>
    </>
  );
}

export default TermsConditions;
