import React, {useEffect} from "react";

function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const handleScroll = () => {};
    
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);
  return (
    <>
      <div className="max-w-[1200px] pb-[4rem] pt-[8rem] m-auto 2xl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[90%] w-[90%] space-y-[20px]">
        <div>
          <h4 className="text-[32px] font-[500]">Privacy Policy</h4>
          <p className="text-[14px] font-[400]">
          This Privacy Policy governs the manner in which Titan Movers USA collects, uses, maintains, and discloses information collected from users (each, a “User”) of the https://www.titanmoversusa.com/ website (“Site”). This privacy policy applies to the Site and all products and services offered by Titan Movers USA.
          </p>
        </div>
        <div>
          <h4 className="text-[16px] font-[500]">Personal Identification Information</h4>
          <p className="text-[14px] font-[400]">We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, subscribe to the newsletter, and in connection with other activities, services, features, or resources we make available on our Site. Users may be asked for, as appropriate, name, and email address. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personal identification information, except that it may prevent them from engaging in certain Site related activities.
          </p>{" "}
        </div>
        <div>
          <h4 className="text-[16px] font-[500]">Non-Personal Identification Information</h4>
          <p className="text-[14px] font-[400]">We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer, and technical information about Users' means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.
          </p>{" "}
        </div>
        <div>
          <h4 className="text-[16px] font-[500]">Web Browser Cookies</h4>
          <p className="text-[14px] font-[400]">Our Site may use “cookies” to enhance User experience. User’s web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. Users may choose to set their web browser to refuse cookies or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.
          </p>{" "}
        </div>
        <div className="space-y-[15px]">
            <h4 className="text-[16px] font-[500]">How We Use Collected Information</h4>
            <p>Titan Movers USA may collect and use Users personal information for the following purposes:</p>
         <ul className="pl-5 space-y-[4px]">
            <li className="text-[14px] font-[400] list-disc">To improve customer service
            </li>
            <li className="text-[14px] font-[400] list-disc">Information you provide helps us respond to your customer service requests and support needs more efficiently.
            </li>
            <li className="text-[14px] font-[400] list-disc">To personalize user experience
            </li>
            <li className="text-[14px] font-[400] list-disc">We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.
            </li>
            <li className="text-[14px] font-[400] list-disc">To improve our Site
            </li>
            <li className="text-[14px] font-[400] list-disc">We may use feedback you provide to improve our products and services.
            </li>
            <li className="text-[14px] font-[400] list-disc">To send periodic emails
            </li>
            <li className="text-[14px] font-[400] list-disc">We may use or share the email address and/or phone number with potential shipping companies to respond to user inquiries, questions, and/or other requests.
            </li>
          </ul>
         </div>
         <div>
          <h4 className="text-[16px] font-[500]">How We Protect Your Information</h4>
          <p className="text-[14px] font-[400]">We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our Site.
          </p>{" "}
          <p className="text-[14px] font-[400]">Sensitive and private data exchange between the Site and its Users happens over a SSL secured communication channel and is encrypted and protected with digital signatures. Our Site is also in compliance with PCI vulnerability standards in order to create as secure of an environment as possible for Users.
          </p>{" "}
        </div>
        <div>
          <h4 className="text-[16px] font-[500]">Sharing Your Personal Information</h4>
          <p className="text-[14px] font-[400]">We share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above. You have the right to request what personal information we have about you and/or to request that we delete or not share your personal information. Please note that you may make such a request only twice during any twelve month period.
          </p>
        </div>
        <div>
          <h4 className="text-[16px] font-[500]">Compliance With Children’s Online Privacy Protection Act</h4>
          <p className="text-[14px] font-[400]">Protecting the privacy of the very young is especially important. For that reason, we never collect or maintain information at our Site from those we actually know are under 13, and no part of our website is structured to attract anyone under 13.
          </p>
        </div>
        <div>
          <h4 className="text-[16px] font-[500]">Changes To This Privacy Policy</h4>
          <p className="text-[14px] font-[400]">Titan Movers USA has the discretion to update this privacy policy at any time. When we do, we will send you an email. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
          </p>
        </div>
        <div>
          <h4 className="text-[16px] font-[500]">Your Acceptance Of These Terms</h4>
          <p className="text-[14px] font-[400]">By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.
          </p>
        </div>
       
      </div>
    </>
  );
}

export default PrivacyPolicy;
