import React from "react";
import stars from "../assets/svgs/starsss.svg";
import iconsb1 from "../assets/svgs/cardic (1).svg";
import iconsb2 from "../assets/svgs/cardic (2).svg";
import cardimg1 from "../assets/images/low-size/cardimg (1).png";
import cardimg4 from "../assets/images/low-size/cardimg (4).png";
import cardimg2 from "../assets/images/low-size/cardimg (2).png";
import cardimg3 from "../assets/images/low-size/cardimg (3).png";

const cardsData = [
    {
      id: 1,
      icon: iconsb2,
      title: "Long Distance Moving",
      name: "David Carl",
      cardImg: cardimg4,
      review: "Impressed with Titan Movers USA' professionalism! They expertly packed and transported my business equipment. Efficient team minimized disruptions. Recommend for commercial moves!",
    },
    {
      id: 2,
      icon: iconsb2,
      title: "Residential Moving",
      name: "Sarah Liam",
      cardImg: cardimg3,
      review: "Titan Movers USA saved the day! Careful packing, furniture assembly, and labeled boxes made everything easy. Smooth move, friendly crew - highly recommend!",
    },
    {
      id: 3,
      icon: iconsb1,
      title: "Long Distance Moving",
      name: "John Mark",
      cardImg: cardimg1,
      review: "Long-distance move with Titan Movers USA? A dream! Clear quote, updates, and safe arrival. Careful crew reduced our stress. Highly recommend!",
    },
    {
      id: 4,
      icon: iconsb1,
      title: "Long Distance Moving",
      name: "Emily Kent",
      cardImg: cardimg2,
      review: "Titan Movers USA made our move a breeze! Customizable service fit our budget. Fast packing, careful transport, and setup help in our new place. Thanks!",
    },
    {
      id: 5,
      icon: iconsb2,
      title: "Long Distance Moving",
      name: "David Carl",
      cardImg: cardimg4,
      review: "Impressed with Titan Movers USA' professionalism! They expertly packed and transported my business equipment. Efficient team minimized disruptions. Recommend for commercial moves!",
    },
    {
      id: 6,
      icon: iconsb2,
      title: "Residential Moving",
      name: "Sarah Liam",
      cardImg: cardimg3,
      review: "Titan Movers USA saved the day! Careful packing, furniture assembly, and labeled boxes made everything easy. Smooth move, friendly crew - highly recommend!",
    },
    {
      id: 7,
      icon: iconsb1,
      title: "Long Distance Moving",
      name: "John Mark",
      cardImg: cardimg1,
      review: "Long-distance move with Titan Movers USA? A dream! Clear quote, updates, and safe arrival. Careful crew reduced our stress. Highly recommend!",
    },
    {
      id: 8,
      icon: iconsb1,
      title: "Long Distance Moving",
      name: "Emily Kent",
      cardImg: cardimg2,
      review: "Titan Movers USA made our move a breeze! Customizable service fit our budget. Fast packing, careful transport, and setup help in our new place. Thanks!",
    },
    {
      id: 9,
      icon: iconsb2,
      title: "Long Distance Moving",
      name: "David Carl",
      cardImg: cardimg4,
      review: "Impressed with Titan Movers USA' professionalism! They expertly packed and transported my business equipment. Efficient team minimized disruptions. Recommend for commercial moves!",
    },
    {
      id: 10,
      icon: iconsb2,
      title: "Residential Moving",
      name: "Sarah Liam",
      cardImg: cardimg3,
      review: "Titan Movers USA saved the day! Careful packing, furniture assembly, and labeled boxes made everything easy. Smooth move, friendly crew - highly recommend!",
    },
    {
      id: 1,
      icon: iconsb1,
      title: "Long Distance Moving",
      name: "John Mark",
      cardImg: cardimg1,
      review: "Long-distance move with Titan Movers USA? A dream! Clear quote, updates, and safe arrival. Careful crew reduced our stress. Highly recommend!",
    },
    {
      id: 12,
      icon: iconsb1,
      title: "Long Distance Moving",
      name: "Emily Kent",
      cardImg: cardimg2,
      review: "Titan Movers USA made our move a breeze! Customizable service fit our budget. Fast packing, careful transport, and setup help in our new place. Thanks!",
    },
    {
      id: 13,
      icon: iconsb2,
      title: "Long Distance Moving",
      name: "David Carl",
      cardImg: cardimg4,
      review: "Impressed with Titan Movers USA' professionalism! They expertly packed and transported my business equipment. Efficient team minimized disruptions. Recommend for commercial moves!",
    },
    {
      id: 14,
      icon: iconsb2,
      title: "Residential Moving",
      name: "Sarah Liam",
      cardImg: cardimg3,
      review: "Titan Movers USA saved the day! Careful packing, furniture assembly, and labeled boxes made everything easy. Smooth move, friendly crew - highly recommend!",
    },
    {
      id: 15,
      icon: iconsb1,
      title: "Long Distance Moving",
      name: "John Mark",
      cardImg: cardimg1,
      review: "Long-distance move with Titan Movers USA? A dream! Clear quote, updates, and safe arrival. Careful crew reduced our stress. Highly recommend!",
    },
    {
      id: 16,
      icon: iconsb1,
      title: "Long Distance Moving",
      name: "Emily Kent",
      cardImg: cardimg2,
      review: "Titan Movers USA made our move a breeze! Customizable service fit our budget. Fast packing, careful transport, and setup help in our new place. Thanks!",
    },
    {
      id: 17,
      icon: iconsb2,
      title: "Long Distance Moving",
      name: "David Carl",
      cardImg: cardimg4,
      review: "Impressed with Titan Movers USA' professionalism! They expertly packed and transported my business equipment. Efficient team minimized disruptions. Recommend for commercial moves!",
    },
    {
      id: 18,
      icon: iconsb2,
      title: "Residential Moving",
      name: "Sarah Liam",
      cardImg: cardimg3,
      review: "Titan Movers USA saved the day! Careful packing, furniture assembly, and labeled boxes made everything easy. Smooth move, friendly crew - highly recommend!",
    },
    {
      id: 19,
      icon: iconsb1,
      title: "Long Distance Moving",
      name: "John Mark",
      cardImg: cardimg1,
      review: "Long-distance move with Titan Movers USA? A dream! Clear quote, updates, and safe arrival. Careful crew reduced our stress. Highly recommend!",
    },
    {
      id: 20,
      icon: iconsb1,
      title: "Long Distance Moving",
      name: "Emily Kent",
      cardImg: cardimg2,
      review: "Titan Movers USA made our move a breeze! Customizable service fit our budget. Fast packing, careful transport, and setup help in our new place. Thanks!",
    },
    {
      id: 21,
      icon: iconsb2,
      title: "Long Distance Moving",
      name: "David Carl",
      cardImg: cardimg4,
      review: "Impressed with Titan Movers USA' professionalism! They expertly packed and transported my business equipment. Efficient team minimized disruptions. Recommend for commercial moves!",
    },
    {
      id: 22,
      icon: iconsb2,
      title: "Residential Moving",
      name: "Sarah Liam",
      cardImg: cardimg3,
      review: "Titan Movers USA saved the day! Careful packing, furniture assembly, and labeled boxes made everything easy. Smooth move, friendly crew - highly recommend!",
    },
    {
      id: 23,
      icon: iconsb1,
      title: "Long Distance Moving",
      name: "John Mark",
      cardImg: cardimg1,
      review: "Long-distance move with Titan Movers USA? A dream! Clear quote, updates, and safe arrival. Careful crew reduced our stress. Highly recommend!",
    },
    {
      id: 24,
      icon: iconsb1,
      title: "Long Distance Moving",
      name: "Emily Kent",
      cardImg: cardimg2,
      review: "Titan Movers USA made our move a breeze! Customizable service fit our budget. Fast packing, careful transport, and setup help in our new place. Thanks!",
    },
  ];
function OurreviewsSec() {
  return (
    <div className="peoplebg py-[2rem] relative ">
    <div className=" 2xl:pt-[2rem] xl:pt-[2rem] lg:pt-[2rem] md:pt-[2rem] sm:pt-[0rem] pt-[0rem] 2xl:pb-[3.3rem] x:pb-[3.3rem] lg:pb-[3.3rem] md:pb-[3.3rem] sm:pb-[0rem] pb-[0rem] space-y-[3rem]">
      <div className="space-y-[15px] 2xl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[90%] w-[90%] m-auto">
        <h2 className="2xl:block xl:block lg:block md:block sm:hidden hidden 2xl:text-[44px] xl:text-[44px] lg:text-[44px] md:text-[44px] sm:text-[24px] text-[24px]  font-[700] 2xl:text-center  xl:text-center lg:text-center md:text-center sm:text-start text-start leading-[110%]">
        <span className="textgr">America loves Titan Movers USA</span> <br />
        Check out our reviews
        </h2>
        <h2 className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block 2xl:text-[44px] xl:text-[44px] lg:text-[44px] md:text-[44px] sm:text-[24px] text-[24px]  font-[700] 2xl:text-center  xl:text-center lg:text-center md:text-center sm:text-center text-center leading-[110%]">
        <span className="textgr">America loves Titan Movers USA </span> 
        Check out our reviews
        </h2>
        <p className="text-[16px] font-[400] max-w-[644px] m-auto text-center">Titan Movers USA can be stressful, but it doesn't have to be! See what real people are saying about their experiences with us.  Our testimonials offer valuable insights into the quality of our service, the professionalism of our movers, and the overall satisfaction of our customers.</p>
      </div>
      <div className="carousel-wrapper">
      <div className="carousel flex space-x-[2rem]">
        {cardsData.map((item, index) => (
          <div key={index} className="carousel-item min-w-[403px] w-[100%]">
            <div className="w-[100%] rounded-[10px] ">
              <div className="bghcardhed flex items-center px-4 space-x-2 py-[9px] bg-[#0F6483] rounded-t-[10px]">
                <img src={item.icon} className="h-[18px]" alt="" />
                <h4 className="text-[#fff] leading-[100%] 2xl:text-[14px] xl:text-[14px] lg:text-[14px]md:text-[14px] sm:text-[13px] text-[13px]">{item.title}</h4>
              </div>
              <div className="flex space-x-3 p-4 bg-[#fff] rounded-b-[10px]">
                <div className="w-[40%]">
                  <img className="w-[100%]" src={item.cardImg} alt="" />
                </div>
                <div className="space-y-[8px] w-[60%]">
                  <div className="pt-[10px]">
                    <h4 className="text-[14px] font-[700] textgr">{item.name}</h4>
                    <img src={stars} alt="" />
                  </div>
                  <p className="text-[14px] font-[400]">{item.review}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
          {cardsData.map((item, index) => (
          <div key={index} className="carousel-item min-w-[403px] w-[100%]">
            <div className="w-[100%] rounded-[10px] ">
              <div className="bghcardhed flex items-center px-4 space-x-2 py-[9px] bg-[#0F6483] rounded-t-[10px]">
                <img src={item.icon} className="h-[18px]" alt="" />
                <h4 className="text-[#fff] leading-[100%]">{item.title}</h4>
              </div>
              <div className="flex space-x-3 p-4 bg-[#fff] rounded-b-[10px]">
                <div className="w-[40%]">
                  <img className="w-[100%]" src={item.cardImg} alt="" />
                </div>
                <div className="space-y-[8px] w-[60%]">
                  <div className="pt-[10px]">
                    <h4 className="text-[14px] font-[700] textgr">{item.name}</h4>
                    <img src={stars} alt="" />
                  </div>
                  <p className="text-[12px] font-[400]">{item.review}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
     
     <div className="2xl:flex xl:flex lg:flex md:flex sm:hidden hidden justify-center absolute bottom-[-3%] w-[100%]">
          <a href="#hero" className="flex justify-center w-[100%] max-w-[350px] m-auto">
          <button className="w-[100%] hover:opacity-[0.9]  max-w-[350px] rounded-[5px] h-[48px] bgbrcolor text-[#fff] font-[700] 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[16px] sm:text-[16px] text-[16px]">
     Get Your Quote Now 
          </button>
              </a>
     </div>
    </div>
    </div>
  );
}

export default OurreviewsSec;
